<template>
  <div class="main w-full img-back">
    <div id="top" class="bg-transparent">
      <div class="flex flex-wrap ml-32 pt-24 mb-6">
        <div id="logo" class="w-3/6">
          <img class="img-logo" src="../assets/images/logo-inicio.png" alt="" />
        </div>
        <div id="menus" class="flex justify-center items-center w-auto mt-5">
          <AppButtonEmpty
            :active="true"
            text="Início"
            class="w-36 h-10 mr-12"
            type-button="default"
            type-text="button-generic"
          />
          <router-link to="/sobre">
            <AppButtonEmpty
              text="Sobre"
              class="w-36 h-10 mr-12"
              type-button="default"
              type-text="button-generic"
            />
          </router-link>
          <!-- <router-link class="text-white underline hover:text-burnedYellow mr-12" to="/instrutor/entrar">
            <BaseText text="Sou Instrutor" type-text="custom" weight="medium" />
          </router-link> -->
          <router-link class="text-white underline hover:text-burnedYellow mr-12" to="/admin/entrar">
            <BaseText text="Área de Login" type-text="custom" weight="medium" />
          </router-link>
        </div>
      </div>
    </div>
    <div id="mid">
      <div class="flex ml-32">
        <div id="text" class="w-3/6 text-white z-10">
          <div class="mt-20">
            <BaseText text="Olá!" type-text="custom" weight="extrabold" size="3xl" />
          </div>
          <div class="mt-1">
            <BaseText
              text="Conhece o projeto"
              color="herbal-hover"
              type-text="custom"
              weight="normal"
              size="3xl"
            />
            <BaseText text="Virando o Jogo?" type-text="custom" weight="bold" size="3xl" />
          </div>
          <div id="description" class="mt-11">
            <BaseText type-text="custom" size="lg">
              O Virando o Jogo é um projeto criado pelo Governo do Estado do Ceará voltado para jovens que não
              estudam e não trabalham. O projeto desenvolve atividades de cunho social e de qualificação
              profissional, facilitando o acesso dos jovens ao mercado de trabalho.
            </BaseText>
          </div>
          <div class="mt-12">
            <BaseText
              text="Para participar é só fazer o pré-cadastro!"
              type-text="custom"
              weight="bold"
              size="xl"
            />
            <br />
            <BaseText text="Vem com a gente?" type-text="custom" weight="medium" size="xl" />
          </div>
          <div class="grid grid-cols-2">
            <div class="flex my-16 mt-20">
              <AppButtonEmpty
                text="Pré-cadastro"
                class="w-56 h-16 mr-10"
                type-button="default"
                type-text="button-generic"
                dispatch="Default/toggleModalPreInscription"
                call-to-action
              />
              <!-- <AppButtonEmpty
              text="Sou Aluno"
              class="w-56 h-16 mr-10"
              type-button="default"
              type-text="button-generic"
            /> -->
            </div>
            <div class="flex my-16 mt-20">
              <a
                href="/docs/LIVRO_SUPERACAO_25X25_CM_DIGITAL.pdf"
                target="_blank"
                class="text-ashes-dark hover:text-burnedYellow"
              >
                <AppButtonEmpty
                  text="Livro Virando o Jogo"
                  class="h-16 mr-10"
                  type-button="default"
                  type-text="button-generic"
                />
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="bot w-full"></div>
    <ModalPreInscription :open="modalPreInscriptionOpen" />
  </div>
</template>

<script>
import AppButtonEmpty from "../components/AppButtonEmpty.vue";
import BaseText from "../components/BaseText.vue";
import ModalPreInscription from "./ModalPreInscription.vue";
import { mapState } from "vuex";

export default {
  components: {
    AppButtonEmpty,
    BaseText,
    ModalPreInscription,
  },

  computed: {
    ...mapState({
      modalPreInscriptionOpen: (state) => state.Default.modalPreInscriptionOpen,
    }),
  },
};
</script>

<style scoped>
#description {
  width: 600px;
}

.img-logo {
  max-width: 100%;
  min-width: 559px;
}

.main {
  background-color: #3ba543;
  background-image: linear-gradient(45deg, #3ba543 49%, #69c968 70%);
  width: 100%;
  min-height: 100vh;
  position: absolute;
}

.img-back {
  background-image: url("../assets/images/background-young.png");
  background-repeat: no-repeat;
  background-position: bottom right;
}

.bot {
  background-image: url("../assets/images/img-rodape.png");
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 55px;
  padding: 10px 0;
  transition: all 0.3s ease-out;
  z-index: 9;
}
</style>
