var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "main w-full img-back" },
    [
      _c("div", { staticClass: "bg-transparent", attrs: { id: "top" } }, [
        _c("div", { staticClass: "flex flex-wrap ml-32 pt-24 mb-6" }, [
          _vm._m(0),
          _c(
            "div",
            {
              staticClass: "flex justify-center items-center w-auto mt-5",
              attrs: { id: "menus" },
            },
            [
              _c("AppButtonEmpty", {
                staticClass: "w-36 h-10 mr-12",
                attrs: {
                  active: true,
                  text: "Início",
                  "type-button": "default",
                  "type-text": "button-generic",
                },
              }),
              _c(
                "router-link",
                { attrs: { to: "/sobre" } },
                [
                  _c("AppButtonEmpty", {
                    staticClass: "w-36 h-10 mr-12",
                    attrs: {
                      text: "Sobre",
                      "type-button": "default",
                      "type-text": "button-generic",
                    },
                  }),
                ],
                1
              ),
              _c(
                "router-link",
                {
                  staticClass:
                    "text-white underline hover:text-burnedYellow mr-12",
                  attrs: { to: "/admin/entrar" },
                },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Área de Login",
                      "type-text": "custom",
                      weight: "medium",
                    },
                  }),
                ],
                1
              ),
            ],
            1
          ),
        ]),
      ]),
      _c("div", { attrs: { id: "mid" } }, [
        _c("div", { staticClass: "flex ml-32" }, [
          _c(
            "div",
            { staticClass: "w-3/6 text-white z-10", attrs: { id: "text" } },
            [
              _c(
                "div",
                { staticClass: "mt-20" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Olá!",
                      "type-text": "custom",
                      weight: "extrabold",
                      size: "3xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-1" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Conhece o projeto",
                      color: "herbal-hover",
                      "type-text": "custom",
                      weight: "normal",
                      size: "3xl",
                    },
                  }),
                  _c("BaseText", {
                    attrs: {
                      text: "Virando o Jogo?",
                      "type-text": "custom",
                      weight: "bold",
                      size: "3xl",
                    },
                  }),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-11", attrs: { id: "description" } },
                [
                  _c(
                    "BaseText",
                    { attrs: { "type-text": "custom", size: "lg" } },
                    [
                      _vm._v(
                        " O Virando o Jogo é um projeto criado pelo Governo do Estado do Ceará voltado para jovens que não estudam e não trabalham. O projeto desenvolve atividades de cunho social e de qualificação profissional, facilitando o acesso dos jovens ao mercado de trabalho. "
                      ),
                    ]
                  ),
                ],
                1
              ),
              _c(
                "div",
                { staticClass: "mt-12" },
                [
                  _c("BaseText", {
                    attrs: {
                      text: "Para participar é só fazer o pré-cadastro!",
                      "type-text": "custom",
                      weight: "bold",
                      size: "xl",
                    },
                  }),
                  _c("br"),
                  _c("BaseText", {
                    attrs: {
                      text: "Vem com a gente?",
                      "type-text": "custom",
                      weight: "medium",
                      size: "xl",
                    },
                  }),
                ],
                1
              ),
              _c("div", { staticClass: "grid grid-cols-2" }, [
                _c(
                  "div",
                  { staticClass: "flex my-16 mt-20" },
                  [
                    _c("AppButtonEmpty", {
                      staticClass: "w-56 h-16 mr-10",
                      attrs: {
                        text: "Pré-cadastro",
                        "type-button": "default",
                        "type-text": "button-generic",
                        dispatch: "Default/toggleModalPreInscription",
                        "call-to-action": "",
                      },
                    }),
                  ],
                  1
                ),
                _c("div", { staticClass: "flex my-16 mt-20" }, [
                  _c(
                    "a",
                    {
                      staticClass: "text-ashes-dark hover:text-burnedYellow",
                      attrs: {
                        href: "/docs/LIVRO_SUPERACAO_25X25_CM_DIGITAL.pdf",
                        target: "_blank",
                      },
                    },
                    [
                      _c("AppButtonEmpty", {
                        staticClass: "h-16 mr-10",
                        attrs: {
                          text: "Livro Virando o Jogo",
                          "type-button": "default",
                          "type-text": "button-generic",
                        },
                      }),
                    ],
                    1
                  ),
                ]),
              ]),
            ]
          ),
        ]),
      ]),
      _c("div", { staticClass: "bot w-full" }),
      _c("ModalPreInscription", {
        attrs: { open: _vm.modalPreInscriptionOpen },
      }),
    ],
    1
  )
}
var staticRenderFns = [
  function () {
    var _vm = this,
      _c = _vm._self._c
    return _c("div", { staticClass: "w-3/6", attrs: { id: "logo" } }, [
      _c("img", {
        staticClass: "img-logo",
        attrs: { src: require("../assets/images/logo-inicio.png"), alt: "" },
      }),
    ])
  },
]
render._withStripped = true

export { render, staticRenderFns }