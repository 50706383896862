var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "div",
    { staticClass: "m-5" },
    [
      _c(
        "div",
        {
          staticClass: "grid grid-cols-5 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "person-data" },
        },
        [
          _vm.student.equipment
            ? _c(
                "div",
                [
                  _c("AppTextField", {
                    attrs: {
                      "show-label": "",
                      "label-text": "Equipamento",
                      text: _vm.student.equipment.name,
                      "type-text": "topic-details",
                    },
                  }),
                ],
                1
              )
            : _c(
                "div",
                [
                  _c("AppTextField", {
                    attrs: {
                      "show-label": "",
                      "label-text": "Equipamento",
                      text: "NÃO INFORMADO",
                      "type-text": "topic-details",
                    },
                  }),
                ],
                1
              ),
          _c(
            "div",
            { staticClass: "col-span-5" },
            [
              _c("BaseText", {
                attrs: { text: "dados pessoais", "type-text": "title-forms" },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-3" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "nome completo",
                  text: _vm.student.name,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "nome social",
                  text: _vm.student.social_name,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "rg",
                  text: _vm.student.rg,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Orgão Expedidor",
                  text: _vm.student.dispatching_agency,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Expedição",
                  text: _vm.student.expedition,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "CPF",
                  text: _vm._f("VMask")(_vm.student.cpf, "###.###.###-##"),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "cidade onde nasceu",
                  text: _vm.student.city_of_birth,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "certidão de nascimento",
                  text: _vm._f("VMask")(
                    _vm.student.birth_certificate,
                    "###### ## ## #### # ##### ### ####### ##"
                  ),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  id: "book",
                  "show-label": "",
                  "label-text": "livro",
                  text: _vm.student.book,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  id: "",
                  "show-label": "",
                  "label-text": "folha",
                  text: _vm.student.page,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "email",
                  text: _vm.student.email,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "telefone",
                  text: _vm._f("VMask")(
                    _vm.student.cellphone_number,
                    "(##) #####-####"
                  ),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "whatsapp de contato",
                  text: _vm._f("VMask")(
                    _vm.student.whatszap,
                    "(##) #####-####"
                  ),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "data de nascimento",
                  text: _vm.formattedBirthdate(_vm.student.birthdate),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "gênero",
                  text: _vm.student.gender,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Se identifica como LGBTQIAPN+",
                  text: _vm.student.identifies_as_lgbtqiapn,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            {},
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "estado civil",
                  text: _vm.getCivilStateName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "NIS",
                  text: _vm.student.nis ? _vm.student.nis : "",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Carteira de Trabalho",
                  text: _vm.student.document_attributes.work_permit,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Carteira de Reservista",
                  text: _vm.student.document_attributes.reserve_card,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Título de Eleitor",
                  text: _vm.student.document_attributes.voter_title,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Deficiência",
                  text: _vm.getDeficiencies(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Pertence a povos originários",
                  text: _vm.student.belongs_to_indigenous_peoples,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Número de Filhos",
                  text: _vm.getNumberSons(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Está gestante?",
                  text: _vm.student.is_pregnant === true ? "Sim" : "Não",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _vm.student.is_pregnant === true
            ? _c(
                "div",
                [
                  _c("AppTextField", {
                    attrs: {
                      "show-label": "",
                      "label-text": "Mês de gestação",
                      text: _vm.student.month_pregnant
                        ? _vm.student.month_pregnant.name
                        : "-",
                      "type-text": "topic-details",
                    },
                  }),
                ],
                1
              )
            : _vm._e(),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "address" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseText", {
                attrs: { text: "endereço", "type-text": "title-forms" },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "cep",
                  text: _vm._f("VMask")(_vm.student.cep, "#####-###"),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "rua",
                  text: _vm.student.street,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "número",
                  text: _vm.student.street_number,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "bairro",
                  text: _vm.getNeighborhoodName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "comunidade",
                  text: _vm.student.community,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "município",
                  text: _vm.getCityName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "estado",
                  text: _vm.getStateName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "complemento (opcional)",
                  text: _vm.student.complement,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "address" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: "dados de matrícula",
                  "type-text": "title-forms",
                },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-5" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Cód. turma atual / mais recente",
                  text: _vm.student.last_team_informations
                    ? _vm.student.last_team_informations.code
                    : "-",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-3" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Histórico de Turmas",
                  text:
                    _vm.student.allCodesTeams &&
                    _vm.student.allCodesTeams.length > 0
                      ? _vm.student.allCodesTeams
                      : "-",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Última presença",
                  text: _vm.getLastPresence(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Curso de Qualificação",
                  text:
                    _vm.student.last_team_informations &&
                    _vm.student.last_team_informations.course
                      ? _vm.student.last_team_informations.course.name
                      : "-",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Status Qualificação",
                  text: _vm.student.qualification_status
                    ? _vm.student.qualification_status.name
                    : "Não informado",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "address" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseText", {
                attrs: { text: "dados de área", "type-text": "title-forms" },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Área [Cadastrada Automaticamente]",
                  text: _vm.student.automatic_area
                    ? _vm.student.automatic_area.name
                    : "-",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Área [Cadastrada Manualmente]",
                  text: _vm.student.manual_area
                    ? _vm.student.manual_area.name
                    : "-",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "address" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: "inserção produtiva",
                  "type-text": "title-forms",
                },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Encaminhamentos da Equipe de Área",
                  text:
                    _vm.student.productive_insertion
                      .forwarding_productive_by_area_teams === 0
                      ? "0"
                      : _vm.student.productive_insertion
                          .forwarding_productive_by_area_teams,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Encaminhamentos da Gestão",
                  text:
                    _vm.student.productive_insertion
                      .forwarding_productive_by_area_management === 0
                      ? "0"
                      : _vm.student.productive_insertion
                          .forwarding_productive_by_area_management,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Inserção Espontânea",
                  text:
                    _vm.student.productive_insertion
                      .spontaneous_productive_insertion_count === 0
                      ? "0"
                      : _vm.student.productive_insertion
                          .spontaneous_productive_insertion_count,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Efetivado a partir de Encaminhamento?",
                  text:
                    _vm.student.productive_insertion
                      .forwarding_productive_insertion_by_feedback_type > 0
                      ? "Sim"
                      : "Não",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-8 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "educational-data" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-8" },
            [
              _c("BaseText", {
                attrs: { text: "dados escolares", "type-text": "title-forms" },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "frequenta a escola?",
                  text: _vm.studying(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-3" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text":
                    "se não frequenta a escola qual a sua última série concluída?",
                  text: _vm.getCompletedSeriesName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Última escola em que estudou?",
                  text: _vm.student.last_school_studied
                    ? _vm.student.last_school_studied.name
                    : "Não informado",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Ano da última matrícula",
                  text: _vm.student.year_of_last_enrollment
                    ? _vm.student.year_of_last_enrollment.name
                    : "Não informado",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Status Escolar",
                  text: _vm.student.educational_status
                    ? _vm.student.educational_status.name
                    : "Não informado",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-8 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "complements-data" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-8" },
            [
              _c("BaseText", {
                attrs: {
                  text: "dados complementares",
                  "type-text": "title-forms",
                },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "tem ocupação",
                  text: _vm.busy(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text":
                    "Em qual Área tem interesse na sua qualificação profissional? Escolha uma ou mais opções.",
                  text: _vm.professionalQualificationNames(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text":
                    "ESCOLHA QUAIS OS TURNOS VOCÊ TEM DISPONÍVEIS PARA PARTICIPAR DO PROJETO",
                  text: _vm.shift(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-2" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "encaminhamento institucional",
                  text: _vm.student.institutional_referral
                    ? _vm.student.institutional_referral.name
                    : "Não informado",
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
          attrs: { id: "bank_account" },
        },
        [
          _c(
            "div",
            { staticClass: "col-span-4" },
            [
              _c("BaseText", {
                attrs: {
                  text: "Dados de conta bancária",
                  "type-text": "title-forms",
                },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
          _c(
            "div",
            { staticClass: "col-span-3" },
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Nome e Código do Banco",
                  text: _vm.getBankName(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Agência",
                  text: _vm.student.agency,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Conta",
                  text: _vm.student.account,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Dígito",
                  text: _vm.student.account_digit,
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
          _c(
            "div",
            [
              _c("AppTextField", {
                attrs: {
                  "show-label": "",
                  "label-text": "Tipo de Conta",
                  text: _vm.getAccountType(),
                  "type-text": "topic-details",
                },
              }),
            ],
            1
          ),
        ]
      ),
      _c(
        "div",
        {
          staticClass: "grid grid-cols-1 gap-x-1 gap-y-6 mb-6",
          attrs: { id: "responsible-data" },
        },
        [
          _c(
            "div",
            [
              _c("BaseText", {
                attrs: {
                  text: "dados do responsável e familiares",
                  "type-text": "title-forms",
                },
              }),
              _c("hr", {
                staticClass: "border-t-0 border-b-2 border-ashes opacity-50",
              }),
            ],
            1
          ),
        ]
      ),
      _vm._l(_vm.student.relatives_attributes, function (relative, index) {
        return _c(
          "div",
          {
            key: index,
            staticClass: "grid grid-cols-4 gap-x-1 gap-y-6 mb-12",
            attrs: { id: "responsible-data" },
          },
          [
            _c("div", { staticClass: "col-span-4" }, [
              _c(
                "div",
                { staticClass: "flex items-end" },
                [
                  _c("AppTextField", {
                    attrs: {
                      "show-label": "",
                      "label-text": "grau de parentesco",
                      text: _vm.getDegreeKinshipName(relative.degree_kinship),
                      "type-text": "topic-details",
                    },
                  }),
                  relative.responsible || relative.reference_person
                    ? _c(
                        "div",
                        { staticClass: "text-container" },
                        [
                          relative.responsible
                            ? _c("BaseText", {
                                attrs: {
                                  color: "burnedYellow",
                                  text: "Responsável Atual",
                                  "type-text": "custom",
                                },
                              })
                            : _vm._e(),
                          relative.reference_person
                            ? _c("BaseText", {
                                attrs: {
                                  color: "burnedYellow",
                                  text: "Pessoa de referência",
                                  "type-text": "custom",
                                },
                              })
                            : _vm._e(),
                        ],
                        1
                      )
                    : _vm._e(),
                ],
                1
              ),
            ]),
            relative.degree_kinship?.id === 16
              ? _c(
                  "div",
                  { staticClass: "col-span-5" },
                  [
                    _c("AppTextField", {
                      attrs: {
                        "show-label": "",
                        "label-text": "especificação do grau de parentesco",
                        text: relative.specification,
                        "type-text": "topic-details",
                      },
                    }),
                  ],
                  1
                )
              : _vm._e(),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "nome completo",
                    text: relative.name,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "rg",
                    text: relative.rg,
                    "type-text": "topic-details",
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "cpf",
                    text: _vm._f("VMask")(relative.cpf, "###.###.###-##"),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "telefone",
                    text: _vm._f("VMask")(
                      relative.cellphone_number,
                      "(##) #####-####"
                    ),
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              { staticClass: "col-span-2" },
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "e-mail",
                    text: relative.email,
                  },
                }),
              ],
              1
            ),
            _c(
              "div",
              [
                _c("AppTextField", {
                  attrs: {
                    "show-label": "",
                    "label-text": "data de nascimento",
                    text: relative.birth_date,
                  },
                }),
              ],
              1
            ),
          ]
        )
      }),
    ],
    2
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }